<template>
  <availability-list-item
    v-if="editMode && newAvailability"
    :is-new="true"
    :availability="newAvailability"
    :timezone="timezone"
    @reset="reset"
  ></availability-list-item>
  <div v-else>
    <div class="button is-primary has-fullwidth" @click="startEditMode">
      <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center my-2">
        <div class="add-icon-container">
          <add-icon class="svg-icon" style="fill: white"></add-icon>
        </div>
        <div class="title">
          {{ $t("business_matching.availability_create_new") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailabilityListItem from "./AvailabilityListItem";
import {mapActions, mapState} from "vuex";
import {getTimestampRangeFromEventDay} from "@/shared/utils";
import AddIcon from "@/assets/icons/add.svg";

export default {
  name: "AvailabilityCreateListItem",

  components: {AvailabilityListItem, AddIcon},

  props: {
    currentDay: {
      type: Object,
      required: true,
    },

    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      editMode: false,
      newAvailability: null,
    };
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["getNewTempAvailability"]),

    startEditMode() {
      this.editMode = true;
    },

    reset() {
      this.editMode = false;
    },
  },

  watch: {
    currentDay: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          const dayRange = getTimestampRangeFromEventDay(newValue);
          this.getNewTempAvailability({
            startTimestamp: dayRange.startTimestamp,
            endTimestamp: dayRange.endTimestamp,
          }).then(result => {
            this.newAvailability = result;
          });
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.add-icon-container {
  border: 2px solid white;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  padding: 8px;
  margin-right: 16px;
}

.title {
  color: white;
  font-weight: normal;
}

.button {
  height: 110px;
  width: 100%;
}
</style>
