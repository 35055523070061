<template>
  <div class="container">
    <div class="columns bm-column-container mt-1 mb-3">
      <div class="column is-hidden-mobile">
        <div class="bm-instruction-buttons has-text-centered mt-3">
          <google-calendar-sync-button class=" has-fullwidth"></google-calendar-sync-button>
          <i-cal-sync-button class="mt-1 has-fullwidth"></i-cal-sync-button>
        </div>
      </div>
      <div class="column">
        <div class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
          <separator class="separator is-primary"></separator>
          <h2 class="title is-2 has-text-centered">
            {{ $t("business_matching.business_matching_availability_title") }}
          </h2>
        </div>
      </div>
      <div class="column">
        <div class="bm-instruction-buttons has-text-centered mt-3">
          <router-link class="button is-primary has-fullwidth " :to="{ name: Constants.ROUTE_BUSINESS_MATCHING }">
            <span class="icon">
              <meeting-icon class="svg-icon has-fill-primary-inverted"></meeting-icon>
            </span>
            <div>
              {{ $t("business_matching.business_matching") }}
            </div>
          </router-link>
          <div class="button is-primary mt-1 has-fullwidth" @click="openInstructionModal">
              <span class="icon">
              <info-icon class="svg-icon has-fill-primary-inverted" style="padding: 3px"></info-icon>
            </span>
            <div>
              {{ $t("business_matching.availability_info_button") }}
            </div>
          </div>
        </div>
      </div>
      <div class="column is-hidden-tablet">
        <div class="has-text-centered">
          <google-calendar-sync-button class="mt-1 mx-1"></google-calendar-sync-button>
          <i-cal-sync-button class="mt-1 mx-1"></i-cal-sync-button>
        </div>
      </div>
    </div>
    <div v-if="availabilityDays.length">
      <div class="is-flex is-flex-direction-column has-text-centered">
        <div class="columns mb-3">
          <div class="column bm-column-container">
            <availability-days-carousel :days="availabilityDays"></availability-days-carousel>
            <template v-if="currentlySelectedDay">
              <availability-day-graph
                class="mb-3"
                :availability="availabilityFromCurrentDay"
                :current-day="currentlySelectedDay"
              ></availability-day-graph>
            </template>
          </div>
        </div>
        <div class="columns mb-2">
          <div class="column bm-column-container" v-if="currentlySelectedDay">
            <transition-group class="px-4" name="list" tag="div">
              <availability-create-list-item
                :current-day="currentlySelectedDay"
                :key="`available-new`"
                :timezone="timezone"
                class="mb-2"
              ></availability-create-list-item>
              <availability-list-item
                v-for="available in availabilityFromCurrentDay"
                :key="`available-${available.startTimestamp}`"
                :availability="available"
                :timezone="timezone"
              ></availability-list-item>
            </transition-group>
          </div>
          <!--          <div class="column is-one-third">-->
          <!--            <div class="has-text-centered is-flex is-flex-direction-column">-->
          <!--              <availability-info class="mt-2"></availability-info>-->

          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>

    </div>
    <div v-else class="placeholder-container">
      <p class="title">
        {{ $t("system.event_finished_error") }}
      </p>
      <p class="subtitle mt-1">
        {{ $t("business_matching.availability_event_finished_error") }}
      </p>
      <div class="button is-primary mt-2" @click="$router.back()">
        {{ $t("business_matching.availability_button_go_back") }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import AvailabilityDaysCarousel from "@/web/components/availability/AvailabilityDaysCarousel";
import AvailabilityListItem from "@/web/components/availability/AvailabilityListItem";
import AvailabilityDayGraph from "@/web/components/availability/AvailabilityDayGraph";
import RequestMixin from "@/shared/mixins/RequestMixin";
import AvailabilityInstructionModal from "@/web/components/availability/AvailabilityInstructionModal";
import AvailabilityCreateListItem from "@/web/components/availability/AvailabilityCreateListItem";
import GoogleCalendarSyncButton from "@/web/components/availability/google/GoogleCalendarSyncButton";
import ICalSyncButton from "./ical/ICalSyncButton";
import MeetingIcon from "@/assets/icons/business_matching.svg";
import Separator from "@/web/components/shared/Separator";
import Constants from "@/web/constants";
import InfoIcon from "@/assets/icons/info.svg";

export default {
  name: "UserAvailability",

  mixins: [RequestMixin],

  components: {
    ICalSyncButton,
    GoogleCalendarSyncButton,
    AvailabilityCreateListItem,
    // AvailabilityInfo,
    AvailabilityDayGraph,
    AvailabilityDaysCarousel,
    AvailabilityListItem,
    MeetingIcon,
    Separator,
    InfoIcon,
  },

  computed: {
    ...mapGetters("businessMatchingAvailability", ["availabilityDays", "availabilityFromCurrentDay", "currentlySelectedDay"]),
    ...mapState(["timezone"]),

    Constants: () => Constants,

    errorMessage() {
      return this.error && this.$options.filters.pluckError(this.error);
    },
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["fetchAvailability"]),

    openInstructionModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AvailabilityInstructionModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
      });
    },
  },

  watch: {
    errorMessage(newValue) {
      if (newValue) {
        this.$root.openErrorModal(newValue);
      }
    },
  },

  mounted() {
    this.fetchAvailability();
  },
};
</script>

<style scoped>
.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
