<template>
  <div class="button is-link" @click="syncWithGoogleCalendar">
    <span class="icon">
      <repeat-icon class="svg-icon has-fill-primary-inverted"></repeat-icon>
    </span>
    <div>
      {{ $t("google_calendar.sync_with_google_calendar") }}
    </div>
  </div>
</template>

<script>
import GoogleCalendarModal from "@/web/components/availability/google/GoogleCalendarModal";
import RepeatIcon from "@/assets/icons/repeat.svg";

export default {
  name: "GoogleCalendarSyncButton",

  components: {RepeatIcon},

  data() {
    return {
      isSignedIn: false,
      events: null,
    };
  },

  methods: {
    async syncWithGoogleCalendar() {
      if (this.isSignedIn) {
        await this.fetchCalendars();
      } else {
        await this.$gapi.login().then(({currentUser, hasGrantedScopes}) => {
          return this.fetchCalendars();
        });
      }
    },

    async fetchCalendars() {
      await this.$gapi.getGapiClient().then(gapi => {
        return gapi.client
          .request({
            method: "GET",
            path: "https://www.googleapis.com/calendar/v3/users/me/calendarList",
            // params: {
            //   // calendarId: "primary",
            //   // timeMin: new Date().toISOString(),
            //   // maxResults: 250,
            //   // orderBy: "startTime",
            // }
          })
          .then(response => {
            this.openGoogleCalendarModal(response.result.items);
          });
      });
    },

    openGoogleCalendarModal(calendars) {
      if (calendars.length) {
        this.$buefy.modal.open({
          parent: this,
          component: GoogleCalendarModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            calendars,
          },
        });
      } else {
        // console.log("Calendar list is empty.");
        //pokaż popup z informacją
      }
    },
  },

  mounted() {
    this.$gapi.listenUserSignIn(isSignedIn => {
      this.isSignedIn = isSignedIn;
    });
  },
};
</script>

<style scoped></style>
