<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <section>
        <div class="title has-text-centered">
          {{ $t("ticket.my_tickets") }}
        </div>

        <b-tabs v-model="tabIndex" position="is-centered" expanded>
          <b-tab-item :label="$t('ticket.event_tickets') + ` (` + eventTicketsCount + `)`"></b-tab-item>
          <b-tab-item :label="$t('ticket.agenda_session_tickets') + ` (` + agendaSessionTicketsCount + `)`"></b-tab-item>
        </b-tabs>
      </section>
    </header>

    <section class="modal-card-body">
      <component :is="tabView" :tickets="tickets"></component>
    </section>

    <footer class="modal-card-foot" :style="{ padding: '20px' }"></footer>
  </div>
</template>

<script>
import EventTicketListView from "@/web/components/tickets/EventTicketListView";
import AgendaSessionTicketListView from "@/web/components/tickets/AgendaSessionTicketListView";

export default {
  name: "TicketsModal",

  components: {EventTicketListView, AgendaSessionTicketListView},

  props: ["eventTickets", "agendaSessionTickets"],

  data() {
    return {
      tabIndex: 0
    };
  },

  computed: {
    tabView() {
      switch (this.tabIndex) {
        case 0:
          return EventTicketListView;
        case 1:
          return AgendaSessionTicketListView;
        default:
          return EventTicketListView;
      }
    },

    tickets() {
      switch (this.tabIndex) {
        case 0:
          return this.eventTickets;
        case 1:
          return this.agendaSessionTickets;
        default:
          return this.eventTickets;
      }
    },

    eventTicketsCount() {
      return this.eventTickets.length;
    },

    agendaSessionTicketsCount() {
      return this.agendaSessionTickets.length;
    }
  }
};
</script>

<style scoped></style>
