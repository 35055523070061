<template>
  <div class="da-container is-relative is-hidden-mobile">
    <div class="da-time-bar is-relative" ref="timeBar" @mousedown="onMouseDown">
      <b-loading class="is-link" :active="isRequesting || isLoading" :is-full-page="false"></b-loading>
      <template>
        <div v-for="(range, index) in savedRanges" :key="`range-${index}`" :style="getRangeStyle(range)"
             class="da-time-selected">
          <div class="bm-range-draggable-area left" @mousedown.stop="editRange(range, rangeBorderConst.LEFT)"></div>
          <div class="bm-range-actions-overlay">
            <trash-bin-icon class="bm-range-remove-button" @click="removeRange(range)"></trash-bin-icon>
          </div>
          <div class="bm-range-draggable-area right" @mousedown.stop="editRange(range, rangeBorderConst.RIGHT)"></div>
        </div>
      </template>
      <div v-if="currentRange" :style="getRangeStyle(currentRange)" class="da-time-selected"></div>
    </div>
    <!-- time indicators -->
    <div v-for="(indicator, index) in timeIndicators" :style="timeIndicatorStyle(index)" :key="index"
         class="da-time-label">
      <div v-if="index % 2 === 0" class="da-time-label-tick">|</div>
      <div class="da-time-label-text">
        {{ indicator }}
      </div>
      <div v-if="index % 2 !== 0" class="da-time-label-tick">|</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { throttle, transformAvailabilityToGraphRanges } from "@/shared/utils";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import RequestMixin from "@/shared/mixins/RequestMixin";

export default {
  name: "AvailabilityDayGraph",

  components: {TrashBinIcon},

  mixins: [RequestMixin],

  props: {
    currentDay: {
      type: Object,
      required: true,
    },

    availability: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isMounted: false,
      isDrawing: false,
      currentRange: null,
      graphBoundingClientRectGraphWidth: null,
    };
  },

  computed: {
    ...mapState(["timezone"]),
    ...mapGetters("businessMatchingAvailability", ["isLoading"]),

    timeIndicators() {
      // ["00:00", ... "23:00", "00:00"]
      return Array.from(Array(25)).reduce((acc, item, index) => {
        acc = [...acc, `${parseToHourWithTrailingZero(index)}:00`];
        return acc;
      }, []);
    },

    savedRanges() {
      if (this.isMounted) {
        return transformAvailabilityToGraphRanges(
          this.availability,
          this.currentDay,
          this.graphBoundingClientRectGraphWidth.width,
          this.timezone
        );
      } else {
        return [];
      }
    },
    rangeBorderConst() {
      return {
        LEFT: "left",
        RIGHT: "right",
      };
    },
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["createNewAvailability", "removeAvailability", "sendAvailability"]),

    timeIndicatorStyle(index) {
      const leftPadding = 2;
      return {
        left: (100 / this.timeIndicators.length) * index + leftPadding + "%",
      };
    },

    getRangeStyle(range) {
      return {
        width: Math.abs(range.width) + "px",
        left: range.startPointX - Math.max(range.width, 0) + "px",
      };
    },

    onMouseDown(event) {
      if (!this.isDrawing && !this.isRequesting && !this.isLoading) {
        this.isDrawing = true;
        this.currentRange = {startPointX: event.pageX - this.graphBoundingClientRectGraphWidth.x, width: 0};
      }
    },

    onMouseMove(event) {
      if (this.isDrawing && this.currentRange) {
        const parentRect = this.graphBoundingClientRectGraphWidth;
        const pointX = event.pageX;
        const currRange = this.currentRange;

        const newWidth = currRange.startPointX - (pointX - parentRect.x);
        const maxWidth = -(parentRect.width - currRange.startPointX);
        const minWidth = currRange.startPointX;
        currRange.width = Math.max(maxWidth, Math.min(minWidth, newWidth));
      }
    },

    onMouseUp(event) {
      if (this.isDrawing) {
        const newAvailability = this.transformRange(this.currentRange);
        if (newAvailability.startTimestamp !== newAvailability.endTimestamp) {
          this.isDrawing = false;
          this.addRangeRequest(newAvailability);
        } else {
          this.isDrawing = false;
          this.currentRange = null;
        }
      }
    },

    editRange(range, border) {
      this.removeAvailability(this.transformRange(range));
      if (border === this.rangeBorderConst.LEFT) {
        this.isDrawing = true;
        if (range.width < 0) {
          const mirroredRange = {
            startPointX: range.startPointX - range.width,
            width: -range.width,
          };
          this.currentRange = mirroredRange;
        } else {
          this.currentRange = range;
        }
      } else if (border === this.rangeBorderConst.RIGHT) {
        this.isDrawing = true;
        this.currentRange = range;
      }
    },

    transformRange(range) {
      const rectWidth = this.graphBoundingClientRectGraphWidth.width;

      const startPoint = range.startPointX - Math.max(range.width, 0);
      const endPoint = startPoint + Math.abs(range.width);
      let startMinutes = (startPoint / rectWidth) * 24 * 60;
      let endMinutes = (endPoint / rectWidth) * 24 * 60;

      //round minutes to full 5 minutes
      if (startMinutes % 5 > 2.5) {
        startMinutes = startMinutes + (5 - (startMinutes % 5));
      } else {
        startMinutes = startMinutes - (startMinutes % 5);
      }
      if (endMinutes % 5 > 2.5) {
        endMinutes = endMinutes + (5 - (endMinutes % 5));
      } else {
        endMinutes = endMinutes - (endMinutes % 5);
      }

      const startDate = new Date(this.currentDay.only_date);
      const endDate = new Date(this.currentDay.only_date);
      startDate.setMinutes(startMinutes);
      endDate.setMinutes(endMinutes);

      return {startTimestamp: startDate.getTime(), endTimestamp: endDate.getTime()};
    },

    removeRange(range) {
      if (!this.isRequesting && !this.isLoading) {
        this.removeAvailability(this.transformRange(range));
      }
    },

    onResize: throttle(function (newVal) {
      this.graphBoundingClientRectGraphWidth = this.$refs.timeBar.getBoundingClientRect();
    }, 20),
  },

  requests: {
    async addRangeRequest(newAvailability) {
      await this.createNewAvailability(newAvailability).finally(res => {
        this.currentRange = null;
      });
    },

    async removeAvailability(availability) {
      await this.removeAvailability(availability);
      await this.sendAvailability();
    },
  },

  mounted() {
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.isMounted = true;
  },

  beforeDestroy() {
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("resize", this.onResize);
  },
};

function parseToHourWithTrailingZero(number) {
  const hour = number % 24;
  return hour < 10 ? `0${hour}` : hour;
}
</script>
