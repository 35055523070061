<template>
  <div class="container my-3 is-fluid">
    <div class="columns" v-if="isAuthorized">
      <div class="column is-narrow">
        <div class="card">
          <div class="card-content">
            <!-- Profile info -->
            <div class="media-center">
              <div class="media-center">
                <action-overlay-view right="6px" top="7px" left="90px">
                  <template>
                    <profile-picture class="is-120x120" :picture="picture" :initials="initials"> </profile-picture>
                  </template>
                  <template #overlay>
                    <label>
                      <figure class="edit-icon-circle is-rounded has-background-primary ">
                        <edit-icon class="svg-icon edit-icon"></edit-icon>
                      </figure>
                      <input
                        type="file"
                        @change="onNewAvatar"
                        accept="image/png, image/jpeg"
                        style="visibility: hidden; width: 0; height: 0"
                      />
                    </label>
                  </template>
                </action-overlay-view>

                <div class="profile-name mt-2 has-text-centered">
                  {{ fullName }}
                </div>
                <div class="profile-position has-text-centered">
                  {{ positionAndCompany || emptyString }}
                </div>

                <countries-list class="mt-2" :countries="country"></countries-list>

                <div class="mt-2" v-if="socialLinks.length > 0">
                  <social-links class="social-links" size="40" :links="socialLinks"></social-links>
                </div>
                <b-loading :active="showLoading" :is-full-page="false" class="is-small"></b-loading>
              </div>
            </div>
          </div>
        </div>

        <!-- MY TICKETS -->
        <div @click="isLoading || openTicketsModal()" class="card with-hover mt-2 is-relative">
          <b-loading :active="isLoading" :is-full-page="false"></b-loading>
          <a class="card-content my-tickets-container">
            <b class="profile-button-text">{{ $t("my_profile.my_tickets") }}</b>
            <div class="has-text-secondary mr-auto">{{ ` (${ticketCount})` }}</div>
            <right-arrow-icon class="right-arrow-icon"></right-arrow-icon>
          </a>
        </div>

        <div class="card with-hover mt-2">
          <router-link :to="{ name: Constants.ROUTE_FRIENDS }" class="card-content my-tickets-container">
            <b class="profile-button-text mr-auto">{{ $t("friends.my_friends") }}</b>
            <right-arrow-icon class="right-arrow-icon"></right-arrow-icon>
          </router-link>
        </div>

        <router-link :to="{ name: Constants.ROUTE_EDIT_PROFILE }" tag="button" class="button is-primary is-fullwidth mt-2">
          {{ $t("edit_profile.edit_my_profile") }}
        </router-link>

<!--        Feature disabled-->
<!--        <router-link :to="{ name: Constants.ROUTE_EDIT_EMAIL }" tag="button" class="button is-primary is-fullwidth mt-1">-->
<!--          {{ $t("auth.change_email") }}-->
<!--        </router-link>-->

        <router-link :to="{ name: Constants.ROUTE_EDIT_PASSWORD }" tag="button" class="button is-primary is-fullwidth mt-1">
          {{ $t("auth.change_password") }}
        </router-link>

        <b-dropdown class="mt-1" aria-role="list" expanded>
          <b-button class="button is-light" slot="trigger" expanded>
            <span>{{ $t("common.more") }}</span>
          </b-button>

          <b-dropdown-item @click="openDeleteAccountDialog()" aria-role="listitem" class="has-text-danger"
            >{{ $t("edit_profile.delete_my_account") }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="column">
        <div class="profile-section-title">
          {{ $t("person_profile.user_biography") }}
        </div>
        <div class="profile-section-content mt-1">
          {{ biography || emptyString }}
        </div>
        <div class="profile-section-title mt-3">
          {{ $t("person_profile.user_my_goals") }}
        </div>
        <div class="profile-section-subtitle mt-1">
          {{ $t("person_profile.user_what_i_offer") }}
        </div>
        <div class="profile-section-content" style="margin-top: 5px">
          {{ whatIOffer || emptyString }}
        </div>
        <div class="profile-section-subtitle mt-1">
          {{ $t("person_profile.user_what_i_need") }}
        </div>
        <div class="profile-section-content" style="margin-top: 5px">
          {{ whatINeed || emptyString }}
        </div>
        <div class="profile-section-title mt-3">
          {{ $t("person_profile.user_contact_details") }}
        </div>
        <div class="mt-1">
          <contact-detail class="contact-detail">
            <template #icon>
              <email-icon class="social-icon"></email-icon>
            </template>
            <p class="title has-text-primary">{{ $t("common.contact_email") }}</p>
            <div class="profile-section-content">{{ email }}</div>
          </contact-detail>

          <contact-detail class="contact-detail">
            <template #icon>
              <phone-icon class="social-icon"></phone-icon>
            </template>
            <p class="title has-text-primary">{{ $t("common.concact_phone") }}</p>
            <div class="profile-section-content">{{ phone || emptyString }}</div>
          </contact-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { mapGetters, mapActions } from "vuex";
import SocialLinks from "@/web/components/shared/SocialLinks";
import ContactDetail from "@/web/components/shared/ContactDetail";
import EmailIcon from "@/assets/contact/email-icon.svg";
import PhoneIcon from "@/assets/contact/phone-icon.svg";
import RightArrowIcon from "@/assets/icon_arrow_right.svg";
import EditIcon from "@/assets/icons/edit.svg";
import Constants from "@/web/constants";
import TicketsModal from "@/web/components/tickets/TicketsModal";
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import CountriesList from "@/web/components/profile/CountriesList";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import DeleteAccountDialog from "@/web/views/dialog/DeleteAccountDialog";

export default {
  name: "MyProfile",

  mixins: [AuthorizedRouteMixin],

  components: {
    CountriesList,
    ActionOverlayView,
    ProfilePicture,
    SocialLinks,
    ContactDetail,
    EmailIcon,
    PhoneIcon,
    RightArrowIcon,
    EditIcon,
  },

  data() {
    return {
      showLoading: false,
    };
  },

  mounted() {
    this.loadTickets();
  },

  computed: {
    ...mapGetters("currentUser", [
      "currentUser",
      "fullName",
      "initials",
      "positionAndCompany",
      "picture",
      "email",
      "phone",
      "biography",
      "whatIOffer",
      "whatINeed",
      "socialLinks",
      "country",
    ]),
    ...mapGetters("userTickets", ["ticket", "agendaSessionTickets", "isLoading"]),

    ticketCount() {
      return (this.ticket ? 1 : 0) + this.agendaSessionTickets.length;
    },

    emptyString() {
      return this.$t("common.empty");
    },

    Constants: () => Constants,
  },

  methods: {
    ...mapActions("currentUser", ["updateUserAvatar"]),
    ...mapActions("userTickets", ["loadTickets"]),

    openTicketsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TicketsModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          eventTickets: this.ticket ? [this.ticket] : [],
          agendaSessionTickets: this.agendaSessionTickets ? this.agendaSessionTickets : [],
        },
      });
    },

    openDeleteAccountDialog() {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteAccountDialog,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
      });
    },

    onNewAvatar(event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        this.showLoading = true;
        this.updateUserAvatar(file).finally(() => (this.showLoading = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  //margin: 60px 120px;
}

.columns {
  flex: 1 0;
}

@media screen and (min-width: 769px), print {
  .column.is-narrow {
    min-width: 0;
    max-width: 340px;
  }
}

.media-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.social-links {
  align-items: center;
  justify-content: center;
}

.contact-detail {
  .title,
  .subtitle {
    margin: 0;
    font-size: 18px;
    line-height: 25px;
  }

  .title {
    font-weight: bold;
    color: var(--main_color);
  }
}

.social-icon {
  fill: #ffffff;
  display: block;
  width: 26px !important;
  height: 26px !important;
}

.my-tickets-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-wrap;
  cursor: pointer;
}

.right-arrow-icon {
  stroke: #828282;
}

.edit-icon-circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.edit-icon {
  margin-left: 3px;
  margin-bottom: 2px;
}
</style>
